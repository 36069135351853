import React from "react";
import { AiFillInstagram, AiFillFacebook } from "react-icons/ai";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";

import { Link } from "react-router-dom";

import { Img } from "component/FrameL";
import Modal from "component/Modal";

import BespokeLogo from "assets/bespoke_footer_icon.png";
import "./style.css";
import FooterPattern from "assets/footer-pattern.jpg";
import Wood from "assets/wood-wide.jpg";
import { useImageViewerContext } from "context/imageViewer";

const Footer = ({ cmsData }) => {
  const { isOpen, closeImage, nextImage, previousImage, activeImage } =
    useImageViewerContext();
  const {
    HotelAddress1,
    HotelAddress2,
    PhoneLocal,
    Facebook,
    Instagram,
    Email,
    TripAdvisor,
  } = cmsData;
  return (
    <>
      <footer
        className={`relative bg-cover px-4 md:px-8 flex flex-row flex-wrap justify-between text-white items-center z-0 footer__container`}
        style={{ backgroundImage: `url(${FooterPattern})` }}
      >
        <div className="w-1/2 md:w-4/12 text-center md:text-left flex flex-col my-8">
          <span>
            Inn At Union Pier
            {HotelAddress1}
          </span>
          <span>9708 Berrien Street {HotelAddress2}</span>
          <span>Union Pier, MI 49129 {HotelAddress2}</span>
          <span className="mt-4">Phone: 269-469-4700 {PhoneLocal}</span>
          <a className="text-white" href="mailto:info@innatunionpier.com">
            info@innatunionpier.com
          </a>
        </div>
        <div className="w-1/2 md:w-4/12 stack-l text-center items-center my-8">
          <div className="flex flex-row items-center text-4xl">
            <a href={Instagram} className="text-white" target="_blank">
              <AiFillInstagram></AiFillInstagram>
            </a>
            <a href={Facebook} className="text-white" target="_blank">
              <AiFillFacebook></AiFillFacebook>
            </a>
          </div>
          <Link className="text-white" to="/policies">
            Privacy Policy
          </Link>
          <p>Copyright {new Date().getFullYear()} </p>
        </div>
        <div className="w-full md:w-4/12 flex flex-col my-8">
          <div className="flex flex-col items-center md:items-end">
            <Img isExternal={false} src={BespokeLogo} alt="Bespoke Logo" />
          </div>
        </div>
      </footer>
      {/* <div className="h-5 bg-repeat-round bg-contain bg-left-top" style={{ backgroundImage: `url(${Wood})` }}></div> */}
      <Modal
        isOpen={isOpen}
        onClose={closeImage}
        className="no-animate bg-olive-green bg-opacity-80"
        closeIconClass="m-2"
      >
        <div className="">
          <Img
            className="mx-auto p-4 md:p-8 lg:p-12 max-h-screen max-w-full"
            src={activeImage}
            onClick={nextImage}
          />
          <button
            className="absolute cursor-pointer top-50p left-0"
            onClick={previousImage}
          >
            <IoIosArrowDropleftCircle className="text-white opacity-80 text-4xl m-4"></IoIosArrowDropleftCircle>
          </button>
          <button
            className="absolute cursor-pointer top-50p right-0"
            onClick={nextImage}
          >
            <IoIosArrowDroprightCircle className="text-white opacity-80 text-4xl m-4"></IoIosArrowDroprightCircle>
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Footer;
