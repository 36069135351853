import React, { useState, useEffect } from "react";
import { IoMdMenu, IoMdClose, IoMdCall, IoIosPin } from "react-icons/io";
import { Link } from "react-router-dom";

import moment from "moment";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";

import FrameL, { Img } from "component/FrameL";
import "./style.css";
import Wood from "assets/wood-wide.jpg";

function DesktopNavbar({ Logo = [] }) {
  const [bookingStart, setBookingStart] = useState(moment(new Date()));
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [bookingEnd, setBookingEnd] = useState(moment(tomorrow));
  const [bookingFocusedInput, setBookingFocusedInput] = useState(null);
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    const handleScrollClose = () => {
      if (window.scrollY < 50) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    };

    document.addEventListener("scroll", handleScrollClose);

    return () => {
      document.removeEventListener("scroll", handleScrollClose);
    };
  }, []);

  return (
    <>
      <div className={`fixed top-0 left-0 m-4 z-50 flex items-center`}>
        <button
          onMouseMove={() => {
            setIsOpen(true);
          }}
          aria-label="Menu"
          className="p-2 tailwind-block"
        >
          <IoMdMenu className="text-4xl"></IoMdMenu>
        </button>
      </div>
      <div className={`navbar__bg_container ${isOpen ? "visible" : ""}`}>
        {/* <div className="h-5 bg-repeat-round bg-contain bg-left-top" style={{ backgroundImage: `url(${Wood})` }}></div> */}
        <div
          className={`navbar__link xl:container mx-auto flex justify-around`}
        >
          <Link to="/accommodations">ROOMS</Link>
          <Link to="/specials">SPECIALS</Link>
          <Link to="/union-pier">UNION PIER</Link>
          <Link to="/" className="navbar__logo">
            <Img
              className="navbar__logo__width"
              Image={Logo}
              objectFit="contain"
            />
          </Link>
          <Link to="/dining">DINING</Link>
          <Link to="/gallery">GALLERY</Link>
          <Link to="/about">ABOUT</Link>
        </div>
      </div>
      <div className={`fixed mt-1 mr-4 top-0 right-0 flex flex-row z-10`}>
        <DateRangePicker
          startDate={bookingStart}
          startDateId="start"
          endDate={bookingEnd}
          endDateId="end"
          onDatesChange={({ startDate, endDate }) => {
            setBookingStart(startDate);
            setBookingEnd(endDate);
          }}
          small={true}
          focusedInput={bookingFocusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={(focusedInput) => setBookingFocusedInput(focusedInput)}
        ></DateRangePicker>
        <a
          href={`https://hotels.cloudbeds.com/en/reservation/KSj7r2?ga_sess_id=1781798054.1702673538#checkin=${
            bookingStart && bookingStart.format("YYYY-MM-DD")
          }&checkout=${bookingEnd && bookingEnd.format("YYYY-MM-DD")}`}
          target="_blank"
          rel="noopener noreferrer"
          className={`bg-olive-green text-white mr-2 btn-sm`}
          style={{ paddingTop: ".1rem", paddingBottom: ".1rem" }}
        >
          Book Now
        </a>
      </div>
    </>
  );
}

function MobileNavbar({ Logo, CoreData }) {
  const [isOpen, setIsOpen] = useState(false);
  const { PhoneLocal } = CoreData;

  const [isBottomOpen, setIsBottomOpen] = useState(true);

  useEffect(() => {
    const handleScrollClose = (e) => {
      if (window.scrollY < 100) {
        setIsBottomOpen(true);
      } else {
        setIsBottomOpen(false);
      }
    };

    document.addEventListener("scroll", handleScrollClose);

    return () => {
      document.removeEventListener("scroll", handleScrollClose);
    };
  }, []);

  return (
    <div className="fixed z-20">
      <div className={`fixed p-4 top-0 left-0`}>
        <button
          className="p-2 bg-gray-200"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <IoMdMenu className="text-4xl text-black"></IoMdMenu>
        </button>
      </div>
      <div
        className={`mobile_nav__bottom p-4 ${isBottomOpen ? "visible" : ""}`}
      >
        <cluster-l>
          <div>
            <a className="p-2 bg-gray-200" href="tel:269-469-4700">
              <IoMdCall className="text-4xl text-black" />
            </a>

            <a
              className="p-2 bg-gray-200"
              href="https://maps.app.goo.gl/FYdLoMeR3e8jDTjC8"
              rel="noopener noreferrer"
              target="_blank"
            >
              <IoIosPin className="text-4xl text-black" />
            </a>
          </div>
        </cluster-l>
      </div>

      <div
        className={`navbar__link text-xl font-expanded uppercase bg-white flex-col flex items-center p-8 py-16 mobile_nav__container ${
          isOpen ? "visible" : ""
        }`}
      >
        <IoMdClose
          className="absolute top-0 right-0 text-4xl m-8"
          onClick={() => {
            setIsOpen(false);
          }}
        />
        <Link
          to="/"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <Img className="navbar__logo--width" Image={Logo} />
        </Link>
        <Link
          to="/accommodations"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          Rooms
        </Link>
        <Link
          to="/specials"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          Specials
        </Link>
        <Link
          to="/union-pier"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          Union Pier
        </Link>
        <Link
          to="/dining"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          Dining
        </Link>
        <Link
          to="/gallery"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          Gallery
        </Link>
        <Link
          to="/about"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          About
        </Link>

        <a
          className="text-xl"
          href="https://hotels.cloudbeds.com/en/reservation/KSj7r2?ga_sess_id=1781798054.1702673538"
          rel="noopener noreferrer"
          target="_blank"
        >
          Book Now
        </a>
      </div>
    </div>
  );
}

const NavBar = ({ CoreData }) => {
  const { Logo } = CoreData.attributes;
  return (
    <React.Fragment>
      <div className="hide-on-lg z-50 relative">
        <DesktopNavbar Logo={Logo.data} />
      </div>
      <div className="block lg:hidden">
        <MobileNavbar Logo={Logo.data} CoreData={CoreData} />
      </div>
    </React.Fragment>
  );
};

export default NavBar;
