/* global dataLayer */


import React, { Suspense,useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import Navbar from "./container/NavBar";
import Footer from "./component/Footer";
import Loader from "./component/Loader";
import { CmsDataWrapper } from "context/cmsData";
import ThingsToDoPage from "./container/ThingsToDo";

const HomePage = React.lazy(() => import("./container/Home"));
const SpecialsPage = React.lazy(() => import("./container/Specials"));
const GalleryPage = React.lazy(() => import("./container/Gallerys"));
const RetreatsPage = React.lazy(() => import("./container/Retreats"));
const UnionPierPage = React.lazy(() => import("./container/UnionPier"));
const RoomsPage = React.lazy(() => import("./container/Rooms"));
const ContactPage = React.lazy(() => import("./container/Contact"));
const PolicyPage = React.lazy(() => import("./container/Policies"));
const PressPage = React.lazy(() => import("./container/About"));
const AboutPage = React.lazy(() => import("./container/About"));
const ItineraryPage = React.lazy(() => import("./container/Itineraries"));
const DiningPage = React.lazy(() => import("./container/Dining"));

function App({ cmsData }) {
  const { "core-data": coreData } = cmsData;

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "G-227FKP20NV");
  }, []);

  return (
    <Suspense fallback={<Loader></Loader>}>
      <div className="App">
        <Navbar CoreData={coreData} />
        <Switch>
          <Route path="/accommodations" component={() => <RoomsPage />} />
          <Route path="/specials" component={() => <SpecialsPage />} />
          <Route
            exact
            path="/thingstodo"
            component={CmsDataWrapper(ThingsToDoPage(), ["things-to-do"])}
          />
          <Route exact path="/dining" component={() => <DiningPage />} />
          <Route exact path="/gallery" component={() => <GalleryPage />} />
          <Route exact path="/retreats" component={() => <RetreatsPage />} />
          <Route exact path="/union-pier" component={() => <UnionPierPage />} />
          <Route exact path="/about" component={() => <AboutPage />} />
          <Route exact path="/press" component={() => <PressPage />} />
          <Route exact path="/contact-us" component={() => <ContactPage />} />
          <Route exact path="/policies" component={() => <PolicyPage />} />
          <Route
            exact
            path="/itineraries/:slug"
            component={() => <ItineraryPage />}
          />
          <Route path="/" component={() => <HomePage />} />
        </Switch>
        <Footer cmsData={coreData} />
      </div>
    </Suspense>
  );
}

export default CmsDataWrapper(App, ["core-data"]);
// export default withRouter(App);
